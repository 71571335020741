<script setup lang="ts"></script>

<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8.59766"
      cy="8.09766"
      r="7.40845"
      stroke="#252525"
      stroke-width="0.987793"
    ></circle>
    <path
      d="M8.00391 12.0494V6.6165H9.18827V12.0494H8.00391ZM8.00391 5.69292V4.44336H9.18827V5.69292H8.00391Z"
      fill="#252525"
    ></path>
  </svg>
</template>

<style scoped></style>
